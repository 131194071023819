.App {
  text-align: center;
  background-color: #1c3144;
}

html {
  background-color: #1c3144;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}

.container-fluid {
  width: 75% !important;
  margin-left: 0% !important;
}

.selected_navbar {
  font-size: 30px !important;
}

.mh-800 {
  max-height: 800px;
  overflow: auto;
}

.pl-5 {
  padding-left: 5px
}

.mt-10 {
  margin-top: 10px
}

.mt-20 {
  margin-top: 20px
}

.mt-50 {
  margin-top: 50px
}

.mb-10 {
  margin-bottom: 10px
}

.mb-40 {
  margin-bottom: 40px
}

.ml-3 {
  margin-left: 3px !important;
}

.ml--20 {
  margin-left: -20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt--15 {
  margin-top: -15px;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80% !important;
}

.w-65 {
  width: 65% !important;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-30 {
  width: 30% !important;
}

.w-15 {
  width: 15% !important;
}

.capitalize {
  text-transform: capitalize;
  font-size: 22px;
}

.left {
  text-align: left;
}

.flex {
  display: flex;
}

.flex_dashboard {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative
}

.item_center {
  align-items: center;
}

.text-center-surv {
  text-align: -webkit-center !important;
}

.bottom_position {
  position: absolute;
  top: 86%;
  left: 44%;
}

.text-center-surv-title {
  text-align: -webkit-center !important;
  font-size: 35px;
  margin: 20px 0px;
}

.place_center {
  place-content: center !important;
}

.surv-text {
  color: #ffbb00;
  font-family: Comic Sans MS, Comic Sans;
}

.surv-text-white {
  color: #ffffff;
  font-family: Comic Sans MS, Comic Sans;
}

.fs-20 {
  font-size: 20px;
}

.text {
  font-family: Comic Sans MS, Comic Sans !important;
  color: white !important;
}

.blue_text {
  font-family: Comic Sans MS, Comic Sans !important;
  color: #1c3144 !important;
}

.logo {
  position: absolute;
  top: 20%;
  left: calc(50% - 150px);
  width: 300px;
}

.sub_logo {
  position: absolute;
  top: calc(20% + 51px);
  left: calc(50% + 72px);
  width: 146px;
  font-size: 27px;
  text-decoration: blink;
  color: #fefefe;
  rotate: 339deg;
  border: none;
  text-shadow: 1px 1px 12px white;
}

.react-datetime-picker {
  min-width: 250px;
  margin-left: 10px;
  margin-top: 54px;
}

.react-datetime-picker__inputGroup>input {
  color: white
}

.react-datetime-picker__inputGroup>span {
  color: white
}

.react-datetime-picker__wrapper>button>svg {
  stroke: white !important
}

.action-svg {
  margin-left: 20px;
}

/*LOGIN PAGE */
.login_page {
  background-color: #1c3144;
  width: 100%;
  height: 100vh;
}

.login_button {
  background: #ffbb00;
  border-radius: 7px;
  box-shadow: 3px 4px 6px #7f7f7f;
  width: 150px;
  margin-top: 15px;
  border: 0;
  margin-bottom: 15px;
  height: 35px;
  color: black;
  font-family: Comic Sans MS, Comic Sans;
}

.button {
  background: #ffbb00;
  border-radius: 7px;
  box-shadow: 3px 4px 6px #7f7f7f;
  width: auto;
  margin-top: 15px;
  border: 0;
  margin-bottom: 0px;
  height: 35px;
  color: black;
  font-family: Comic Sans MS, Comic Sans;
  padding-left: 24px;
  padding-right: 24px;
}

.disabled_button {
  background: #b9b9b9;
  border-radius: 7px;
  box-shadow: 3px 4px 8px #868686;
  width: 70px;
  margin-top: 15px;
  border: 0;
  margin-bottom: 0px;
  height: 35px;
}

.send_button {
  width: 150px;
}

.surv-select {
  margin-top: 10px;
  height: 38px;
  width: 300px !important;
  color: grey;
}

.surv-input {
  height: 42px;
  width: 80px !important;
  margin-top: 1px !important;
  border-radius: 6px;
  border: 1px solid #cccccc;
}

.content-center {
  justify-content: center;
  align-items: center;
}

.surv-DateTime>div {
  border-radius: 7px !important;
  border: 1px solid #cccccc;
  height: 45px;
  padding: 5px;

}

.orange {
  color: orange
}

.red {
  color: red
}

.green {
  color: green
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.bg_orange {
  background-color: orange;
}

.bg_green {
  background-color: green;
}

.bg_red {
  background-color: red;
}

.bg-navbar {
  background-color: #1c3144 !important;
}

.navbar-toggler {
  width: 150px !important;
  height: 100px !important;
}

.nav-link {
  font-size: 24px;
}

.padb-4 {
  padding-bottom: 4px !important;
}

.center_login {
  position: absolute;
  width: 400px;
  height: 200px;
  left: calc(50% - 200px);
  top: calc(50vh - 100px);
}

.header_logo {
  width: 200px;
  position: absolute;
  right: 50px;
  top: 10px;
}

.sub_header_logo {
  position: absolute;
  top: 35px;
  right: 1px;
  width: 146px;
  font-size: 22px;
  text-decoration: blink;
  color: #fefefe;
  rotate: 339deg;
  border: none;
  text-shadow: 1px 1px 12px white;
}

.surv-table_s {
  width: 30%;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
}

.surv-table {
  width: 50%;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
}

.surv-table-editions {
  width: 80%;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
}

.surv-table-editions,
.surv-table-editions>thead>th,
.surv-table-editions>tbody>tr>td {
  /*border: 1px solid white;*/
}

.surv-table-editions>tbody>tr:nth-child(even) {
  background-color: #3b4f618f;
}

.surv-table-configurations {
  width: 80%;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 65px;
}

.surv-table-configurations,
.surv-table-configurations>thead>th,
.surv-table-configurations>tbody>tr>td {
  /*border: 1px solid white;*/
}

.surv-table-configurations>tbody>tr:nth-child(even) {
  background-color: #3b4f618f;
}

.surv-table-dashboard {
  width: 100%;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 65px;

}

.surv-table-dashboard,
.surv-table-dashboard>thead>th,
.surv-table-dashboard>tbody>tr>td {
  /*border: 1px solid white;*/
}

.surv-table-dashboard>tbody>tr:nth-child(even) {
  background-color: #3b4f618f;
}

.edition-table-cont {
  overflow: auto;
  height: 85vh;
}

.edition-table-cont::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.edition-table-cont::-webkit-scrollbar-track {
  background: #1c3144;
}

/* Handle */
.edition-table-cont::-webkit-scrollbar-thumb {
  background: #ffc107;
}

/* Handle on hover */
.edition-table-cont::-webkit-scrollbar-thumb:hover {
  background: #ffc107;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1c3144;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffc107;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc107;
}

.surv-svg {
  padding-left: 10px;
  cursor: pointer;
}

.empty_svg {
  height: 30px;
  width: 40px;
}

.surv-table-editions>thead {
  overflow: auto;
  height: 86px;
  position: sticky;
  top: -6px;
  background-color: #1c3144;
}

.surv-table-configurations>thead {
  overflow: auto;
  height: 86px;
  position: sticky;
  top: -6px;
  background-color: #1c3144;
}

th {
  height: 40px;
  color: #ffc107;
  font-size: 35px;
}

tr {
  background-color: #1c3144;
  color: white;
  font-family: Comic Sans MS, Comic Sans;
  text-transform: capitalize;
  height: 40px;
  font-size: 19px;
}

.result_input {
  width: 70%;
}

.modal_content_container {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.modal_header {
  color: #ffc107;
  font-size: 29px;
  font-family: Comic Sans MS, Comic Sans;
}

.modal_content_title {
  width: 95%;
  padding-left: 3%;

}

.modal_p_title {
  font-size: 20px;
  color: #ffc107;
  font-family: Comic Sans MS, Comic Sans;
  padding-left: 3%;
}

.select_text_edit {
  color: white;
  font-family: Comic Sans MS, Comic Sans;
  margin-left: 3%;
  font-size: 16px;
  width: 85%;
  text-align: -webkit-left;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 3px;
  font-style: italic;
}

.admin_text {
  color: white;
  font-family: Comic Sans MS, Comic Sans;
  margin-left: 3%;
  font-size: 16px;
  width: 85%;
}

.admin_text_message {
  color: white;
  font-family: Comic Sans MS, Comic Sans;
  margin-left: 3%;
  font-size: 16px;
  width: 30%;
}

.admin_text_message_code {
  color: white;
  font-family: Comic Sans MS, Comic Sans;
  margin-left: 3%;
  font-size: 14px;
  width: 90%;
}

.admin_text_message_code_preview {
  color: white;
  font-family: Comic Sans MS, Comic Sans;
  margin-left: 3%;
  font-size: 13px;
  width: 90%;
  background-color: #101e2b94;
}

.edit_field {
  margin-left: 20px;
  cursor: pointer;
  align-self: center
}

.input_text {
  color: black !important;
}

.save_button_cont {
  position: relative;
  margin-left: 3%;
  margin-top: 3%;

}

.save_button {
  background-color: #ffbb00;
  border-radius: 40px;
  height: 30px;
  width: 100px;
  border: none;
  font-family: Comic Sans MS, Comic Sans;
  font-size: 16px;
}

.cache_button {
  background-color: #860202;
  border-radius: 18px;
  height: 58px;
  width: 156px;
  border: none;
  font-family: Comic Sans MS, Comic Sans;
  font-size: 16px;
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
}

.home_cache {
  align-items: center;
  justify-content: center;
  margin-top: 13px;
}

.id_cache {
  width: 12%;
  margin: 25px 16px 11px 19px;
  height: 44px;
  border-radius: 6px;
}

.select-lega-cache {
  width: 16% !important;
  margin-left: 20px;
  min-width: 180px;
}

.home_text_cache {
  margin-top: 13px;
}

/*Leghe*/
.team_ico {
  width: 35px;
  height: 35px;
  background-size: cover;
  background-repeat: no-repeat;
}

.default_ico {
  background-image: url('./resources/team_ico.png');
}

.Soccer_ico {
  background-image: url('./resources/football_ico.png');
}
.mode-2_ico {
  background-image: url('./resources/mode-2_ico.png');
}
.mode-1_ico {
  background-image: url('./resources/mode-1_ico.png');
}
.mode-0_ico {
  background-image: url('./resources/mode-0_ico.png');
}
.Tennis_ico {
  background-image: url('./resources/tennis_ico.png');
}

.matchday_ico {
  background-image: url('./resources/matchday.png');
}

.deadline_ico {
  background-image: url('./resources/deadline.png');
}

.visible_ico {
  background-image: url('./resources/visible.png');
}

.not_visible_ico {
  background-image: url('./resources/not_visible.png');
}

.cards_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ReactModal__Overlay {
  z-index: 4 !important
}

.league_card {
  width: 300px;
  background: #1c2333cf;
  border-radius: 8px;
  margin: 2% 1%;
  color: white;
  padding-bottom: 10px;
  position: relative;
}

.league_card_disabled {
  width: 300px;
  background: #ababab;
  border-radius: 8px;
  margin: 2% 1%;
  color: white;
  padding-bottom: 10px;
  position: relative;
}

.league_card_title {
  background: #214f6f;
  color: white;
  font-family: Comic Sans MS, Comic Sans !important;
  padding: 7px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.league_card_title_disabled {
  background: #939393;
  color: #464646e0;
  font-family: Comic Sans MS, Comic Sans !important;
  padding: 7px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card_label {
  font-family: Comic Sans MS, Comic Sans !important;
  margin-right: 20px;
  margin-left: 5px;
}

.card_label_xs {
  font-family: Comic Sans MS, Comic Sans !important;
  margin-right: 20px;
  margin-left: 5px;
  font-size: 13px;
}

.orange_bubble {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 2px;
  background-color: orange;
  right: 5px;
  top: 9px;
}

.eraser {
  position: absolute;
  width: 25px;
  height: 20px;
  margin-right: 13px;
  margin-left: 2px;
  right: 22px;
  top: 12px;
}

.trasher {
  position: absolute;
  width: 25px;
  height: 20px;
  margin-right: 13px;
  margin-left: 2px;
  right: 45px;
  top: 6px;
}

.green_bubble {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 2px;
  background-color: green;
  right: 5px;
  top: 9px;
}

.red_bubble {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 2px;
  background-color: red;
  right: 5px;
  top: 9px;
}

.add_bubble {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 2px;
  background-color: #ffc107;
  align-self: center;
  margin-top: 6px;
  cursor: pointer;
}

.play_bubble {
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 2px;
  background-color: rgb(10 140 17);
  right: 5px;
  bottom: 9px;
}

.stop_bubble {
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 2px;
  background-color: rgb(99, 8, 8);
  right: 5px;
  bottom: 9px;
}

.bubble_content {
  position: absolute;
  left: 9px;
  top: 4px;
  color: white;
  cursor: pointer;
}

.select_text {
  font-family: Comic Sans MS, Comic Sans;
  margin-left: 3%;
  font-size: 16px;
  width: 250px;
}

.team_text {
  font-family: Comic Sans MS, Comic Sans;
  margin-left: 3%;
  font-size: 16px;
  width: 100px;
}

/*LOADING*/
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #101e2b;
  box-shadow: 30px 0 0 #ffc107;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #ffc107;
  box-shadow: 30px 0 0 #101e2b;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #ffc107;
  }

  50% {
    box-shadow: 0 0 0 #ffc107;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #ffc107;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #101e2b;
  }

  50% {
    box-shadow: 0 0 0#101e2b;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #101e2b;
    margin-top: 0;
  }
}

.loader_cont {
  position: absolute;
  top: 45%;
  left: 48%;
}

/*Stepper component */
#RFS-StepperContainer {
  /* width: 50%;*/
  width: 85%;
}

#RFS-Label {
  color: white !important;
  font-family: Comic Sans MS, Comic Sans !important;
}

.home_perc {
  width: 130px;
  height: 40px;
  border-radius: 8px;
  border: 0;
  margin-left: 20px;
}

td {
  vertical-align: bottom;
}

.open_row {
  margin-top: 20px
}

.close_matches_page {
  padding-bottom: 30px;
}

body>div.ReactModalPortal>div>div>div>div.modal_content>div:nth-child(12)>div>div.react-switch-bg>div:nth-child(1) {
  padding-left: 6px;
}

body>div.ReactModalPortal>div>div>div>div.modal_content>div:nth-child(12)>div>div.react-switch-bg>div:nth-child(2) {
  padding-left: 6px;
}

.round_button {
  position: fixed;
  right: 25px;
  bottom: 35px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #ffc107;
  border: 0;
  border-color: #ffc107;
  font-size: 36px;
  color: white;
  padding-bottom: 5px;
}

.round_button {
  position: fixed;
  right: 25px;
  bottom: 35x;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #ffc107;
  border: 0;
  border-color: #ffc107;
  font-size: 36px;
  color: white;
  padding-bottom: 5px;
}

.add_team_button {
  position: fixed;
  right: 25px;
  bottom: 90px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #ffc107;
  border: 0;
  border-color: #ffc107;
  font-size: 36px;
  color: white;
  padding-bottom: 5px;
  background-image: url('resources/add_team_3.png');
  background-size: contain
}

.number_of_team:hover {
  background-color: #214f6f;
}

.tshirt_template {
  width: 65px;
  height: 65px;
  background-image: url('resources/tshirt_template.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
  position: absolute;
}

.delete_team {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.edit_team_ico {
  position: absolute;
  right: 40px;
  top: 5px;
  cursor: pointer;
}

.edit_text_ico {
  position: absolute;
  right: 100px;
  top: 65px;
  cursor: pointer;
}

.back_to_league {
  align-items: center;
  display: flex;
  margin-left: 50px;
}

.tshirt_template_1 {
  width: 65px;
  height: 65px;
  background-image: url('resources/tshirt_template_1.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
  position: absolute;
}

.tshirt_template_2 {
  width: 65px;
  height: 65px;
  background-image: url('resources/tshirt_template_2.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
  position: absolute;
}

.doughnut_chart_card {
  width: 55%;
  height: 350px;
  box-shadow: 0px 0px 2px #7d7d7d;
  overflow: hidden;
  padding: 15px;
  margin: 2%;
  border-radius: 6px;
  background: #00000014;
}

.legend_tr {
  height: 25px;
}

.legend_th {
  height: 25px;
  color: white;
  font-size: 18px;
}

.legend_td {
  width: auto;
  min-width: 120px;
  max-width: 250px;
}

.totale {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: translate(0%, 20%);
  font-size: 15px;
  color: white;
  width: 106px;
  height: 25px;
}

canvas {
  height: 250px !important;
  width: 250px !important;
  margin-top: 100px;
  padding: 15px;
}

div.dashboard_table>table>tbody>tr {
  font-size: 15px;
}

.dashboard_title {
  color: #d1d1d1 !important;
  text-align-last: left;
  width: 98% !important;
  margin: 2%;
  padding-top: 20px;
  font-family: Comic Sans MS, Comic Sans !important;
  font-size: 35px;
}

.counter_card {
  width: 20%;
  height: 100px;
  overflow: hidden;
  padding: 25px;
  margin: 2%;
  border-radius: 6px;
  box-shadow: 0px 0px 2px #7d7d7d;
  background: #00000014;
  color: white;
  min-width: 150px;
}

.counter_title {
  font-family: Comic Sans MS, Comic Sans !important;
  font-size: 25px;
}

.counter_text {
  font-family: Comic Sans MS, Comic Sans !important;
  font-size: 30px;
}

.operations_table {
  width: 70%;
  margin-left: 2%;
  padding-bottom: 20px;
  margin-bottom: 100px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #7d7d7d;
}

.active_operation {
  width: 20px;
  height: 20px;
  background: #019301;
  border-radius: 3px;
}

.inactive_operation {
  width: 20px;
  height: 20px;
  background: #950000;
  border-radius: 3px;
}

td {
  vertical-align: middle !important;
}

#root>div>div>div.text-center-surv.edition-table-cont>table>tbody>tr:nth-child(3) {
  max-width: 100px;
}

rect {
  width: 100%;
  height: 100%;
  fill: #69c;
  stroke: #069;
  stroke-width: 5px;
  opacity: 0.5
}

@media(max-width:1250px) {
  .doughnut_chart_card {
    width: 93%;
    min-width: 420px;
  }

  /* .flex_dashboard {
    display: grid;
    justify-items: center;
    overflow: auto;
    height: 1000px;
    margin-bottom: 25px
  }
*/
  .dashboard_table {
    margin-top: 10px
  }
}

@media(max-width:1000px) {
  .container-fluid {
    width: 100% !important;
    margin-left: 0% !important;
  }

  .dashboard_card_w {
    width: 100% !important;
  }

  .navbar-toggler {
    width: 84px !important;
    height: 80px !important;
  }

  .canvas {
    width: 200px !important;
    height: 200px !important;
    padding: 0;
  }

  .totale {
    font-size: 12px !important;
    transform: translate(5%, 20%) !important;
  }


  .surv-table {
    width: 90%;
  }

  .action-svg {
    margin-left: 0px;

  }

  .surv-svg {
    width: 25px;
  }

  .empty_svg {
    width: 25px;
  }

  .surv-table-editions>thead>th {
    font-size: 13px;
  }

  .surv-table-editions>tbody>tr {
    font-size: 13px;
  }

  .surv-table-configurations>thead>th {
    font-size: 12px;
  }

  .surv-table-configurations>tbody>tr {
    font-size: 10px;
  }

  .ReactModal__Content {
    width: 80% !important;
  }

  canvas {
    max-height: 250px;
    max-width: 250px;
  }

  .totale {
    font-size: 15px;
  }

  .operations_table {
    width: 95%;
  }

  .operations_table>tr {
    font-size: 12px;
  }

  .operations_table>tr>td>div>.react-datetime-picker {
    margin-top: 50px;
    min-width: 170px;
  }

  .inactive_operation,
  .active_operation {
    width: 10px;
    height: 10px;
  }
}

@media(max-width:750px) {
  .flex_dashboard {
    display: grid;
    justify-items: center;
    height: 550px !important;
  }

  .totale {
    width: 200px;
    left: 5%;
    transform: translate(0%, 0%) !important;
  }

}

@media(max-width:500px) {
  .dashboard_title {
    font-size: 25px;
  }

  .totale {
    width: 200px;
    left: 10%;
    transform: translate(0%, 0%) !important;
  }

  .surv-table-configurations>tbody>tr {
    font-size: 7px;
  }

  .round_button {
    /*left: 25px;*/
    bottom: 30px;
  }

  .surv-table>tbody>tr {
    font-size: 15px;
  }

  .disabled_button {
    background: #b9b9b9;
    border-radius: 7px;
    box-shadow: 3px 4px 8px #868686;
    width: 80%;
    margin-top: 17px;
    border: 0;
    margin-bottom: 0px;
    height: 31px;
  }

  .button {
    margin-bottom: 0px;
    height: 31px;
    width: 80%;
    margin-top: 17px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .operations_table {
    width: 95%;
  }
}

/*
@media(max-width:1000px) {
  .surv-table {
    width: 95%;
  }

  .result-input {
    width: 100%;
  }

  .surv-table-editions {
    width: 100%;

  }

  .blu_text {
    width: 64%;
    height: 56px;
  }

  .center_login {
    width: 95%;
    left: 2%;
  }

  .login_text {
    font-size: 25px;
  }

  .login_button {
    width: 250px;
    height: 80px;
    font-size: 25px;
  }

  .navbar-nav {
    font-size: 25px !important;
  }

  .header_logo {
    width: 300px;
    position: absolute;
    right: 50px;
    top: 10px;
  }

  .sub_header_logo {
    position: absolute;
    top: 65px;
    right: 44px;
    width: 146px;
    font-size: 34px;
    text-decoration: blink;
    color: #fefefe;
    rotate: 339deg;
    border: none;
    text-shadow: 1px 1px 12px white;
  }

  .nav-item {
    font-size: 40px !important;
  }

  .selected {
    font-size: 55px !important;
  }

  .surv-select {
    width: 80% !important;
    font-size: 25px !important;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  #RFS-StepperContainer {
    width: 90% !important;
  }

  #RFS-Label {
    font-size: 25px !important;
  }

  tr {
    font-size: 20px !important;
  }

  .text {
    font-size: 25px !important;
  }

  .ReactModal__Content {
    width: 90% !important;
  }

  .modal_p_title {
    font-size: 40px !important;
  }

  .admin_text {
    font-size: 25px !important;
  }

  .edit_field {
    width: 50px !important;
    height: 50px !important;
  }

  .bi-x {
    width: 100px !important;
    height: 95px !important;
  }

  .close_matches_page {
    padding-bottom: 30px;
  }

  .surv-table-editions>thead>th {
    font-size: 25px;
  }
}
*/